import * as React from 'react';
import ModalDialog from './ModalDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import { useDropzone } from 'react-dropzone';

import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';

import axios from 'axios';

import isEmail from 'validator/lib/isEmail';

import globals from '../globals';

import s from '../settings';

const baseUrl = s[s.config].apiUrl.replace('/api','');

let timer = null;

export default function ContactDialog(props) {
  const [imageData, setImageData] = React.useState(null);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState({});
  const [attachments, setAttachments] = React.useState([]); // State to store selected files

  const fetchCaptchaImage = async () => {
    try {
      const response = await axios.get('math-captcha');
      const base64String = response.data; // Assuming the response.data contains the base64 string

      // Set the base64 string as the image data
      setImageData(base64String);

      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      
      timer = setTimeout(() => fetchCaptchaImage(),55000);
    } catch (err) {
      console.error('Error fetching image:', err);
    }
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    const maxFiles = 5;
    const maxSize = 5 * 1024 * 1024; // 5MB
  
    // Show an alert for any files rejected due to size
    fileRejections.forEach(rejection => {
      if (rejection.file.size > maxSize) {
        alert(globals.uit._file_is_to_large + ` ${maxSize / (1024 * 1024)}MB.`);
      }
    });
  
    // Check if the new total count exceeds the maximum allowed files
    if (attachments.length + acceptedFiles.length > maxFiles) {
      alert(globals.uit._limit_images_to_5);
      return;
    }
  
    // Update attachments state with valid files only
    setAttachments(prevAttachments => [
      ...prevAttachments,
      ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    ]);
  };
  
  // Initialize react-dropzone with a size limit check
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024 // 5MB
  });

  const onSend = async () => {
    const _errors = {};
    ['note', 'email', 'captcha', 'gdpr'].forEach(key => {
      if (!data[key]) {
        _errors[key] = key === 'gdpr' ? globals.uit._gdpr_required : globals.uit._contact_required;
      }
    });
  
    if (!_errors.email && !isEmail(data['email'])) {
      _errors.email = globals.uit._email_error || 'Invalid email';
    }
  
    if (Object.keys(_errors).length > 0) {
      setError({ ..._errors });
    } else {
      try {
        const formData = new FormData();
        formData.append('key', imageData.key);
        formData.append('captcha', data.captcha);
        formData.append('note', data.note);
        formData.append('email', data.email);
  
        // Attach each file in the attachments array
        attachments.forEach((file, index) => {
          formData.append(`attachments[${index}]`, file);
        });
  
        await axios.post('notify', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        props.onClose({
          severity: 'success',
          msg: globals.uit._notify_success,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const sendError = err.response.data.error;
          if (sendError === 'captcha') {
            fetchCaptchaImage();
            setError({ ...error, captcha: globals.uit._captcha_error });
          } else if (sendError === 'email') {
            alert(globals.uit._email_send_error);
          }
        }
      }
    }
  };  

  return (
      <ModalDialog 
        fullWidth={true}
        disableEscapeKeyDown={true}
        maxWidth={'sm'}
        onClose={props.onClose} title={globals.uit._contact_note_title}>
        <div >
            <div>
                <TextField
                  onChange={e => setData(Object.assign(data, {note: e.target.value}))}
                  id="outlined-multiline-static"
                  sx={{width:'100%'}} 
                  label={globals.uit._contact_note}
                  multiline
                  helperText={error.note}
                  error={error.note ? true : false}
                  rows={5}
                />
            </div>
            <div {...getRootProps()} style={{ border: '1px dashed #cccccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
              <input {...getInputProps()} />
              <p>{globals.uit._drag_and_drop}</p>
              <div>
                {attachments.map((file, index) => (
                  <img key={index} src={file.preview} alt="preview" width={100} style={{ margin: '5px' }} />
                ))}
              </div>
            </div>
            <div>
                <TextField
                    onChange={e => setData(Object.assign(data, {email: e.target.value}))}
                    size="small"
                    sx={{width:'100%'}}
                    label="e-mail"
                    type={"email"}
                    helperText={error.email}
                    error={error.email ? true : false}
                />
            </div>
            <br></br>
            <FormControl error={error.gdpr ? true : false} required>
              <FormGroup>
                <FormControlLabel required control={<Checkbox onChange={e => {
                  const value = e.target.checked;
                  
                  if (value===true) {
                    fetchCaptchaImage();
                  }
                  else {
                    clearTimeout(timer);
                    timer = null;
                    setImageData(null);
                  }

                  setData(Object.assign(data, {gdpr: value}))
                }}/>} label={
                  <div style={{fontSize:'11px'}}>{globals.uit.gdpr}</div>
                  } />
                  <FormHelperText>{error.gdpr}</FormHelperText>
              </FormGroup>
            </FormControl>
            {imageData && (
                <div>
                  <br></br>
                  <div style={{marginBottom:'10px'}}>{globals.uit._captcha}</div>
                    <div style={{display: 'flex'}}>
                      <div>
                        <img
                            src={imageData.img}
                        />
                        <FormHelperText><a onClick={()=>fetchCaptchaImage()} href="#">{globals.uit._refresh_captcha}</a></FormHelperText>
                      </div>
                      <div style={{flex: 1}}>
                        <TextField 
                          error={error.captcha ? true : false} 
                          helperText={error.captcha} 
                          style={{marginTop:'-2px', width:'100%'}} 
                          onChange={e => setData(Object.assign(data, {captcha: e.target.value}))} size="small">
                        </TextField>
                      </div>
                    </div>
                </div>
            )}
            <br></br>
            <div style={{display:'flex'}}>
            <Button onClick={onSend} style={{marginLeft:'auto', marginRight:0, marginTop: '10px'}} variant="outlined" endIcon={<SendIcon />}>
                {globals.uit._contact_send}
            </Button>
            </div>
        </div>
      </ModalDialog>
  );
}
